// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderCard {
  display: inline-block;
  background: white;

  height: 600px;
  width: 500px !important;
  margin: 10px;
  padding: 20px;
  border-radius: 12px;
}

.slick-slide {
  padding: 0 10px;
}

.slick-list {
  margin: 0 -10px; /* Adjust margin to align the slides properly */
}
.sliderImage img {
  object-fit: contain;
  width: 100%;
}

.sliderHeading h6 {
  text-align: start;
  padding-top: 49px;
  padding-bottom: 20px;
}
.sliderDescription p {
  text-align: start;
}
.slick-dots li button {
}
.slick-dots li {
  width: 10px !important;
}
.slick-dots li button:before {
  font-size: 12px !important;
}
.slick-dots {
  bottom: -60px;
}

.slick-next {
  right: 1px !important;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background: grey !important;
}

.slick-next:before {
  line-height: 1.7 !important;
  padding-left: 4px;
}

.slick-prev {
  left: 1px !important;
  background: grey !important;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  z-index: 23;
}

.slick-prev:before {
  line-height: 1.7 !important;
  padding-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/slider.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;;EAEjB,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe,EAAE,+CAA+C;AAClE;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,iBAAiB;AACnB;AACA;AACA;AACA;EACE,sBAAsB;AACxB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,2BAA2B;EAC3B,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB","sourcesContent":[".sliderCard {\n  display: inline-block;\n  background: white;\n\n  height: 600px;\n  width: 500px !important;\n  margin: 10px;\n  padding: 20px;\n  border-radius: 12px;\n}\n\n.slick-slide {\n  padding: 0 10px;\n}\n\n.slick-list {\n  margin: 0 -10px; /* Adjust margin to align the slides properly */\n}\n.sliderImage img {\n  object-fit: contain;\n  width: 100%;\n}\n\n.sliderHeading h6 {\n  text-align: start;\n  padding-top: 49px;\n  padding-bottom: 20px;\n}\n.sliderDescription p {\n  text-align: start;\n}\n.slick-dots li button {\n}\n.slick-dots li {\n  width: 10px !important;\n}\n.slick-dots li button:before {\n  font-size: 12px !important;\n}\n.slick-dots {\n  bottom: -60px;\n}\n\n.slick-next {\n  right: 1px !important;\n  height: 30px;\n  width: 30px;\n  border-radius: 20px;\n  background: grey !important;\n}\n\n.slick-next:before {\n  line-height: 1.7 !important;\n  padding-left: 4px;\n}\n\n.slick-prev {\n  left: 1px !important;\n  background: grey !important;\n  height: 30px;\n  width: 30px;\n  border-radius: 20px;\n  z-index: 23;\n}\n\n.slick-prev:before {\n  line-height: 1.7 !important;\n  padding-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
