import { useEffect, useRef } from "react";
import "../styles/description.css";
import eggGloabl from "../assets/images/eggGlobal.png";
import seemless from "../assets/images/seemlessIntegration.svg";
import enhanceSec from "../assets/images/enhanceSecurity.svg";
import optimizing from "../assets/images/optimizing.svg";
import simplified from "../assets/images/simplified.svg";
import wideAdoption from "../assets/images/wideAdoption.svg";
import instantInsight from "../assets/images/instantInsight.svg";
import crossDevice from "../assets/images/crossDevice.svg";
import ecommerse from "../assets/images/eCommerceB.svg";
import bankingB from "../assets/images/bankingB.svg";
import onlineB from "../assets/images/onlineGamingB.svg";
import streamingB from "../assets/images/streamingServiceB.svg";
import travelB from "../assets/images/travelB.svg";
import { MultipleItems } from "../pages/slider";

export const BrowserIntelligence = () => {
  return (
    <div className="singlePage">
      <div className="container ">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="container1">
              <div className="outer-circle">
                <div className="inner-circle">
                  <div className="content">
                    <div>
                      <button>FRAUD WATCH</button>
                    </div>
                    <div className="contentHeading circle_header">
                      Browser Intelligence
                    </div>
                    <div className="description">
                      <p>
                        In the evolving digital landscape, understanding and
                        securing browser interactions are vital for businesses
                        to safeguard against threats and optimize user
                        experiences. Fstac’s Browser Intelligence is an advanced
                        solution that provides deep insights into browser
                        behavior, utilizing real-time analysis, smart signals,
                        and anomaly detection to protect your platform. This
                        solution is scalable, integrates seamlessly, and adapts
                        to your specific security needs, offering businesses a
                        powerful tool to prevent fraud and enhance operational
                        efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="outerCircleIcon1">
                    <img src={eggGloabl} alt="" />
                  </div>
                  <div className="outerCircleIcon2">
                    <img src={eggGloabl} alt="" />
                  </div>
                </div>
                <div className="outerCircleIcon">
                  <img src={eggGloabl} alt="" />
                </div>
              </div>
              <div className="outerBox"></div>
            </div>
          </div>
        </div>
        {/* <div className="row">
        <div className="col-lg-12">
          <div className="NewBox">
            <p>hello</p>
          </div>
        </div>
      </div> */}
      </div>
      <div className="container singlePageBox">
        <div className="row text-center">
          <div className="col-lg-12">
            <div className="keyFeatureHeading">
              <div>
                <h5>Key Features</h5>
              </div>
              <div>
                <h2>Secure, Insightful, Powerful</h2>
              </div>
            </div>
          </div>
          <div
            className="col-lg-12 "
            style={{ paddingTop: "50px", paddingBottom: "40px" }}
          >
            <div className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox">
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Seamless Integration</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Fstac’s IP Intelligence integrates seamlessly with your
                    existing systems, offering real-time, scalable IP data
                    analysis that adapts as your business grows. Whether you’re
                    operating a startup or managing a global enterprise, our
                    solution ensures that you’re equipped with the critical
                    insights necessary to safeguard and enhance your operations.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={seemless} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Enhanced Security</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Our solution boosts your security framework by providing
                    precise geolocation accuracy, detecting proxies, VPNs, and
                    bots, and assigning risk scores to IPs. This multifaceted
                    approach allows you to proactively prevent fraud, protect
                    transactions, and ensure user authenticity, all while
                    maintaining a secure and reliable environment.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={enhanceSec} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Instant Insights</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    With Fstac’s powerful API, access to IP intelligence is
                    instantaneous, enabling real-time, data-driven
                    decision-making without disrupting your workflow. Whether
                    detecting suspicious IP patterns or verifying user
                    legitimacy, our solution keeps you ahead of potential
                    threats, ensuring your business operations are both secure
                    and efficient.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={instantInsight} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Consistent Cross-Device Experience</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Fstac’s IP Intelligence guarantees a consistent and secure
                    experience across all devices, whether users access your
                    platform via mobile, desktop, or other connected devices.
                    This ensures that your security protocols and user
                    experiences are uniformly maintained, regardless of how
                    customers interact with your platform.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={crossDevice} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Wide Adoption and Global Coverage</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    Our extensive IP intelligence coverage spans IP addresses
                    worldwide, allowing you to secure and optimize a larger,
                    more diverse customer base. This broad coverage also
                    supports geo-blocking and access control measures, ensuring
                    your compliance with regional regulations and enhancing your
                    ability to manage user access effectively.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={wideAdoption} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Optimized Marketing and Personalization</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    By analyzing IP geolocation and behavior patterns, Fstac’s
                    IP Intelligence allows you to deliver personalized content
                    and offers, driving user engagement and satisfaction. It
                    also filters out fraudulent traffic, ensuring that your
                    marketing efforts reach genuine users, thereby maximizing
                    your return on investment (ROI).
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={optimizing} alt="" />
              </div>
            </div>
            <div
              className="singleCard d-flex justify-content-between align-items-center p-3 singleCardBox"
              style={{ marginTop: "30px" }}
            >
              <div className="contentSection text-start">
                <div className="headingSinglePage">
                  <h6>Simplified Reconciliation and Compliance</h6>
                </div>
                <div className="descriptionContent">
                  <p>
                    With instant payment confirmations and detailed transaction
                    records, Fstac’s IP Intelligence simplifies financial
                    reconciliation processes and helps maintain compliance with
                    global data protection regulations. Our ability to enforce
                    geo-blocking policies and monitor access based on user
                    location ensures your business remains legally compliant
                    across various jurisdictions.
                  </p>
                </div>
              </div>
              <div className="singlePageContent">
                <img src={simplified} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="sliderBox">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12">
              <div className="sliderHeadingContent text-center">
                <h2>
                  See how our platform <br /> works quickly and easily!
                </h2>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <MultipleItems />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 ">
            <div className="UseCases text-center p-4">
              <h5>Use Case by Industry</h5>
              <p>
                Businesses often curate special offers for specific customers,
                such as first-time buyers, members of a referral program, and so
                on.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center align-items-center"
          style={{ marginBottom: "30px" }}
        >
          <div className="col-lg-10">
            <div className="row justify-content-center  align-items-center">
              <div className="col-lg-12">
                <div className="parentCard1">
                  <div className="managementCard1 managementCardB1 ">
                    <div className="iconImage">
                      <img src={ecommerse} alt="" />
                    </div>
                    <div>
                      <div className="cardHeading">
                        <h3>E-Commerce</h3>{" "}
                      </div>
                      <div className="cardDescription">
                        <p>
                          By correlating IP address with device and browser
                          fingerprints, e-commerce platforms can detect unusual
                          login attempts from unfamiliar environments, reducing
                          the risk of account takeovers.
                        </p>
                        <p>
                          Identifies inconsistencies between IP, device, and
                          browser data during transactions helps prevent
                          fraudulent payments and chargebacks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="managementCard1 managementCardB2">
                    <div className="iconImage">
                      <img src={bankingB} alt="" />
                    </div>
                    <div>
                      <div className="cardHeading">
                        <h3>Banking & Financial Services</h3>
                      </div>
                      <div className="cardDescription">
                        <p>
                          By combining IP intelligence with device and browser
                          fingerprints ensures that only recognized devices from
                          known locations can access sensitive financial
                          accounts, blocking potential fraudsters.
                        </p>
                        <p>
                          By analyzing mismatches in IP and device/browser data,
                          financial institutions can identify and halt phishing
                          attempts where attackers use different environments to
                          mimic legitimate users.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="managementCard1 managementCardB3">
                    <div className="iconImage">
                      <img src={onlineB} alt="" />
                    </div>
                    <div>
                      <div className="cardHeading">
                        <h3>Online Gaming</h3>
                      </div>
                      <div className="cardDescription">
                        <p>
                          By matching IP data with device and browser
                          fingerprints, gaming platforms can detect and prevent
                          users from creating multiple accounts to exploit
                          promotions or cheat.
                        </p>
                        <p>
                          Real-time detection of discrepancies between IP and
                          device/browser data can stop fraudulent in-game
                          transactions and protect player accounts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="managementCard1 managementCardB4">
                    <div className="twoCard" style={{ marginBottom: "20px" }}>
                      <div className="iconImage" style={{ width: "20%" }}>
                        <img src={streamingB} alt="" />
                      </div>
                      <div className="twoBoxContent" style={{ width: "80%" }}>
                        <div className="cardHeading">
                          <h3 style={{ paddingTop: "0px" }}>
                            Streaming Services
                          </h3>{" "}
                        </div>
                        <div className="cardDescription">
                          <p>
                            Streaming services can use IP and fingerprinting
                            data to identify and restrict unauthorized account
                            sharing across different locations and devices.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="twoCard">
                      <div className="iconImage" style={{ width: "20%" }}>
                        <img src={travelB} alt="" />
                      </div>
                      <div className="twoBoxContent" style={{ width: "80%" }}>
                        <div className="cardHeading">
                          <h3 style={{ paddingTop: "0px" }}>
                            Travel & Hospitality
                          </h3>{" "}
                        </div>
                        <div className="cardDescription">
                          <p>
                            Travel companies can use these technologies to
                            detect and prevent the abuse of loyalty programs by
                            tracking unusual access patterns across IPs and
                            devices.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
