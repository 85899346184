import "../styles/contactus.css";

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../styles/contactus.css";
import { ThankYouModal } from "./thankyou";
// Define the shape of the form values
interface ContactFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  platforms: {
    MobileApp: boolean;
    Website: boolean;
  };
  protections: {
    accountTakeover: boolean;
    fakeAccounts: boolean;
    referralPromoAbuse: boolean;
    paymentFraud: boolean;
    incentiveAbuse: boolean;
    spamAbuse: boolean;
    identityFraud: boolean;
    adFraud: boolean;
  };
  description: string;
  termsAccepted: boolean;
}

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must be at least 2 characters.")
    .max(50, "First name can't exceed 50 characters.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Last name must be at least 2 characters.")
    .max(50, "Last name can't exceed 50 characters.")
    .required("Last name is required."),
  email: Yup.string()
    .email("Invalid email format.")
    .required("Email is required."),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits.")
    .required("Phone number is required."),
});

export const ContactUs: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  // Initial values for the form
  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    platforms: {
      MobileApp: false,
      Website: false,
    },
    protections: {
      accountTakeover: false,
      fakeAccounts: false,
      referralPromoAbuse: false,
      paymentFraud: false,
      incentiveAbuse: false,
      spamAbuse: false,
      identityFraud: false,
      adFraud: false,
    },
    description: "",
    termsAccepted: false,
  };

  const dataModifid = (data: any[]) => {
    if (data.length > 0) {
      return data;
    } else {
      return null;
    }
  };

  const handleSubmit = async (values: ContactFormValues) => {
    // Prepare the payload
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      platforms: dataModifid(
        Object.keys(values.platforms).filter(
          (key) => values.platforms[key as keyof typeof values.platforms]
        )
      ),
      protections: dataModifid(
        Object.keys(values.protections).filter(
          (key) => values.protections[key as keyof typeof values.protections]
        )
      ),
      description: values.description === "" ? null : values.description,
    };

    try {
      const response = await fetch("https://uat0.fstac.tech/id/website/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setModalOpen(true);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }

    // setModalOpen(true);

    // console.log("Payload submitted:", payload);
  };

  const closeModal = (resetForm: () => void) => {
    setModalOpen(false);
    resetForm(); // Reset the form when the modal closes
  };

  return (
    <div id="contactUs">
      <div className="container">
        <div className="contactUs">
          <div className="row justify-content-center align-items-center pb-5">
            <div className="col-lg-6">
              <div className="contactHeading">
                <h5 className="text-center">Contact Us</h5>
                <h2 className="text-center">Get Started Today</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9">
              <div className="contactUsFrom">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    isValid,
                    dirty,
                    resetForm,
                    errors,
                    touched,
                  }) => (
                    <Form className="p-5">
                      <div className="firstName_">
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="firstName"
                              style={{ paddingBottom: "10px" }}
                            >
                              First Name
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="text"
                              name="firstName"
                              className={`inputTag ${
                                errors.firstName && touched.firstName
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="First Name"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="lastName"
                              style={{ paddingBottom: "10px" }}
                            >
                              Last Name
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="text"
                              name="lastName"
                              className={`inputTag ${
                                errors.lastName && touched.lastName
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Last Name"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="firstName_">
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="email"
                              style={{ paddingBottom: "10px" }}
                            >
                              Company Email
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="email"
                              name="email"
                              className={`inputTag ${
                                errors.email && touched.email
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="inputBox">
                          <div className="label">
                            <label
                              htmlFor="phone"
                              style={{ paddingBottom: "10px" }}
                            >
                              Number
                            </label>
                          </div>
                          <div className="inputTag">
                            <Field
                              type="text"
                              name="phone"
                              className={`inputTag ${
                                errors.phone && touched.phone
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="number"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="inputBox">
                        <div className="label">
                          <label>
                            The platform(s) you’re looking to protect:
                          </label>
                        </div>
                        <div className="checkbox_Box d-flex">
                          <div className="checkbox_single_box">
                            <Field
                              type="checkbox"
                              name="platforms.MobileApp"
                              checked={values.platforms.MobileApp}
                              onChange={handleChange}
                            />
                            <label>Mobile App</label>
                          </div>
                          <div className="checkbox_single_box">
                            <Field
                              type="checkbox"
                              name="platforms.Website"
                              checked={values.platforms.Website}
                              onChange={handleChange}
                            />
                            <label>Website</label>
                          </div>
                        </div>
                      </div>

                      <div className="inputBox">
                        <div className="label">
                          <label>
                            What are you looking to protect against:
                          </label>
                        </div>
                        <div className="checkbox_Box_1">
                          {Object.keys(initialValues.protections).map(
                            (protection) => (
                              <div
                                key={protection}
                                className="checkbox_single_box_1"
                              >
                                <Field
                                  type="checkbox"
                                  name={`protections.${protection}`}
                                  checked={
                                    values.protections[
                                      protection as keyof typeof initialValues.protections
                                    ]
                                  }
                                  onChange={handleChange}
                                />
                                <label>
                                  {protection.replace(/([A-Z])/g, " $1").trim()}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <div className="inputBox">
                        <Field
                          type="text"
                          name="description"
                          placeholder="Brief description of your requirements (optional)"
                        />
                      </div>

                      <div className="termAndCondtion">
                        <div className="checkbox_term">
                          <label>
                            {/* <Field type="checkbox" name="termsAccepted" /> */}
                            By submitting this form, you agree to our{" "}
                            <span className="colorChangeText">
                              Terms of Use
                            </span>{" "}
                            &{" "}
                            <span className="colorChangeText">
                              Privacy Policy
                            </span>
                            .
                          </label>
                          <ErrorMessage
                            name="termsAccepted"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="bookDemo"
                          disabled={!(isValid && dirty)}
                        >
                          Book a Demo
                        </button>
                      </div>
                      <ThankYouModal
                        isOpen={isModalOpen}
                        onClose={() => closeModal(resetForm)}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export const ContactUs = () => {
//   return (
//     <div id="contactUs">
//       <div className="container">
//         <div className="contactUs">
//           <div className="row justify-content-center align-items-center pb-5">
//             <div className="col-lg-6">
//               <div className="contactHeading">
//                 <div>
//                   <h5 className="text-center">Contact Us</h5>
//                 </div>
//                 <div>
//                   <h2 className="text-center">Get Started Today</h2>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row justify-content-center align-items-center">
//             <div className="col-lg-8">
//               <div className="contactUsFrom">
//                 <form action="" className="p-5">
//                   <div className="d-flex justify-content-between firstName_">
//                     <div className="inputBox">
//                       <div className="label">
//                         <label>First name</label>
//                       </div>
//                       <div className="inputTag">
//                         <input type="text" value="" placeholder="First Name" />
//                       </div>
//                     </div>
//                     <div className="inputBox">
//                       <div className="label">
//                         <label>Last name</label>
//                       </div>
//                       <div className="inputTag">
//                         <input type="text" value="" placeholder="Last Name" />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="">
//                     <div className="inputBox">
//                       <div className="label">
//                         <label>Company Email</label>
//                       </div>
//                       <div className="inputTag">
//                         <input type="email" value="" placeholder="Email" />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="">
//                     <div className="inputBox">
//                       <div className="label">
//                         <label>
//                           The platform(s) you’re looking to protect:
//                         </label>
//                       </div>
//                       <div className="checkbox_Box d-flex">
//                         <div className="checkbox_single_box">
//                           <input
//                             type="checkbox"
//                             id="MobileApp"
//                             name="MobileApp"
//                             defaultValue="MobileApp"
//                           />
//                           <label className="checkbox_" htmlFor="MobileApp">
//                             Mobile App
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box">
//                           <input
//                             type="checkbox"
//                             id="Website"
//                             name="Website"
//                             defaultValue="Website"
//                           />
//                           <label className="checkbox_" htmlFor="Website">
//                             {" "}
//                             Website
//                           </label>
//                         </div>
//                         <div></div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="">
//                     <div className="inputBox">
//                       <div className="label">
//                         <label>What are you looking to protect against:</label>
//                       </div>
//                       <div className="checkbox_Box_1 ">
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="accountTakeover"
//                             name="accountTakeover"
//                             defaultValue="accountTakeover"
//                           />
//                           <label
//                             className="checkbox_"
//                             htmlFor="accountTakeover"
//                           >
//                             Account Takeover
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="fakeAccounts"
//                             name="fakeAccounts"
//                             defaultValue="fakeAccounts"
//                           />
//                           <label className="checkbox_" htmlFor="fakeAccounts">
//                             {" "}
//                             Fake Accounts
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="referralPromoAbuse"
//                             name="referralPromoAbuse"
//                             defaultValue="referralPromoAbuse"
//                           />
//                           <label
//                             className="checkbox_"
//                             htmlFor="referralPromoAbuse"
//                           >
//                             {" "}
//                             Referral & Promo Abuse
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="paymentFraud"
//                             name="paymentFraud"
//                             defaultValue="paymentFraud"
//                           />
//                           <label className="checkbox_" htmlFor="paymentFraud">
//                             {" "}
//                             Payment Fraud
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="incentiveAbuse"
//                             name="incentiveAbuse"
//                             defaultValue="incentiveAbuse"
//                           />
//                           <label className="checkbox_" htmlFor="incentiveAbuse">
//                             {" "}
//                             Incentive Abuse
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="spamAbuse"
//                             name="spamAbuse"
//                             defaultValue="spamAbuse"
//                           />
//                           <label className="checkbox_" htmlFor="spamAbuse">
//                             {" "}
//                             Spam & Abuse
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="identityFraud"
//                             name="identityFraud"
//                             defaultValue="identityFraud"
//                           />
//                           <label className="checkbox_" htmlFor="identityFraud">
//                             {" "}
//                             Identity Fraud
//                           </label>
//                         </div>
//                         <div className="checkbox_single_box_1">
//                           <input
//                             type="checkbox"
//                             id="adFraud"
//                             name="adFraud"
//                             defaultValue="adFraud"
//                           />
//                           <label className="checkbox_" htmlFor="adFraud">
//                             {" "}
//                             Ad Fraud
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="">
//                     <div className="inputBox">
//                       <div className="optional">
//                         <input
//                           type="text"
//                           value=""
//                           placeholder="Brief decription of your requirements (optional)"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="termAndCondtion">
//                     <div className="checkbox_term">
//                       <label className="checkbox_" htmlFor="termAndCondition">
//                         By submitting this form, you agree to our{" "}
//                         <span className="colorChangeText">Terms of Use</span> &{" "}
//                         <span className="colorChangeText"> Privacy Policy</span>
//                         .
//                       </label>
//                     </div>
//                   </div>
//                   <div>
//                     <button className="bookDemo">Book a Demo</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
