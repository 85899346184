import "../styles/privacyPolicy.css";
import { GetInTouch } from "./getInTouch";
export const PrivacyPolicy = () => {
  return (
    <>
      <div id="privacyPolicy">
        <div className="container">
          <div className="privacyPolicyContent">
            <div className="row justify-content-center align-items-center pb-5">
              <div className="col-lg-12">
                <div className="contactHeading">
                  <div>
                    <h5 className="text-center">Fstac</h5>
                  </div>
                  <div>
                    <h2 className="text-center">Privacy Policy</h2>
                  </div>
                </div>
                <div className="pt-4">
                  <p>
                    Stackpe Technosolutions Private Limited is committed to
                    protecting the privacy of our users.
                  </p>
                  <p>
                    This Privacy Policy explains how we collect, use, and share
                    your personal information when you use our platform. By
                    using Stackpe Technosolutions Private Limited, you agree to
                    this Privacy Policy.
                  </p>
                </div>
                <div className="pt-3">
                  <div className="policySubHeading">
                    <h6>Information We Collect</h6>
                  </div>
                  <div>
                    <p>
                      We collect personal information that you provide to us
                      when you use Stackpe Technosolutions Private Limited. This
                      information may include your name, email address, phone
                      number, address, and payment information. We may also
                      collect information about your use of our platform, such
                      as your IP address, browser type, and device type.
                    </p>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="policySubHeading">
                    <h6>Use of Information</h6>
                  </div>
                  <div>
                    <p>
                      We use the personal information we collect to operate and
                      improve our platform and to provide you with the services
                      you request. This may include processing payments,
                      communicating with you about your account, and providing
                      customer support. We may also use your personal
                      information to send you marketing communications about
                      Stackpe Technosolutions Private Limited and our partners.
                    </p>
                    <p>
                      You may opt-out of receiving these communications at any
                      time by following the unsubscribe instructions in the
                      communication or contacting us.
                    </p>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="policySubHeading">
                    <h6>Sharing of Information</h6>
                  </div>
                  <div>
                    <p>
                      We may share your personal information with third-party
                      service providers who help us operate our platform,
                      process payments, or provide other services. These service
                      providers are required to maintain the confidentiality and
                      security of your personal information. We may also share
                      your personal information with law enforcement or
                      government agencies if required by law or to protect our
                      rights or the rights of others.
                    </p>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="policySubHeading">
                    <h6>Security</h6>
                  </div>
                  <div>
                    <p>
                      Stackpe Technosolutions Private Limited takes the security
                      of your personal information seriously. We are compliant
                      and have implemented reasonable physical, technical, and
                      administrative measures to protect your personal
                      information from unauthorized access, use, or disclosure.
                      Our servers are hosted in secure data centers that employ
                      the latest security protocols and techniques to safeguard
                      your data.
                    </p>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="policySubHeading">
                    <h6>Changes to this Privacy Policy</h6>
                  </div>
                  <div>
                    <p>
                      Stackpe Technosolutions Private Limited may update this
                      Privacy Policy from time to time. We will notify you of
                      any changes by posting the updated policy on our website.
                      Your continued use of our platform after the effective
                      date of the updated policy will signify your acceptance of
                      the changes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <GetInTouch
          heading="Ready to transform your financial journeys?"
          subheading="We’ll make your business safer. Lets discuss how"
          buttonText="Explore APIs"
        />
      </div>
    </>
  );
};
