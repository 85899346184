import "../styles/privacyPolicy.css";
import { GetInTouch } from "./getInTouch";
export const TermAndCondition = () => {
  return (
    <>
      <div id="privacyPolicy">
        <div className="container">
          <div className="privacyPolicyContent">
            <div className="row justify-content-center align-items-center pb-5">
              <div className="col-lg-12">
                <div className="contactHeading">
                  <div>
                    <h5 className="text-center">Fstac</h5>
                  </div>
                  <div>
                    <h2 className="text-center">Terms & Conditions</h2>
                  </div>
                </div>
                <div className="pt-4">
                  <p>
                    The following are the terms and conditions ("Terms") that
                    governs the use of the Stackpe Technosolutions Private
                    Limited website (the "Platform") and create a legal
                    agreement between you and Techindo System Pvt Ltd (the
                    "Company").
                  </p>
                  <p>
                    By accessing or using the Platform, you agree to be bound by
                    these Terms. If you do not agree with the Terms, please do
                    not use or access the Platform. When you use the Platform,
                    you acknowledge that the Company acts only as a facilitator
                    of transactions between registered users of the Platform,
                    including the users' customers and financial institutions
                    (Acquirer, Bank, any other Payment Provider) Definitions The
                    following terms used in these Terms shall have the meanings
                    assigned to them below: "Intellectual Property Rights" shall
                    mean any patents, copyrights, trademarks, trade secrets,
                    know- how, service marks, database rights, inventions,
                    topographies, trade and business names, domain names, marks
                    and devices, design rights, moral rights, or any other
                    property rights (whether registered or not) that provide
                    similar rights, anywhere in the world. "Services" shall mean
                    a digitally-enabled online payment checkout service that
                    allows merchants to accept payments from their customers
                    using various modes of payment, including credit cards,
                    debit cards, buy now pay later, unified payments interface,
                    net-banking, online wallets, and other online payment
                    methods. "User" or "you" shall mean any person, including
                    any merchant and financial partners, who accesses, uses,
                    installs, or views the Platform (even if you do not avail of
                    the Services provided). "User Data" shall mean any personal
                    and/or sensitive data, information, user transaction data,
                    including but not limited to: Aadhaar number and personal
                    information such as your name, address, date of birth,
                    telephone number, gender, e-mail address, location, proof of
                    identity, and other financial and credit information
                    relating to you, including but not limited to bank details,
                    account information, and other details. "Website" shall mean
                    assets on the Stackpe Technosolutions Private Limited domain
                    and any other asset developed, designed, and operated by the
                    Company for the provision of Services. Services The Company
                    provides the Platform & API Services to enable you to access
                    the Services. The Company will act on your explicit
                    instructions when providing the Services, and any
                    transactions carried out on your behalf will be subject to
                    your sole consent, discretion, and responsibility. The
                    Company will partner with financial institutions or
                    third-party banks or other entities to provide the Services.
                    To access the Services, you may be required to create a user
                    profile on the Platform and provide personal and financial
                    information, including but not limited to name, email
                    address, gender, date of birth, contact information, bank
                    details, and documents, PAN card, and other information
                    including User Data. You acknowledge that providing such
                    information is necessary for the use of the Services and
                    agree that such information shall be accurate and complete.
                    If the information provided is inaccurate or incomplete, the
                    Company may cancel or refuse any part of the Services
                    offered to you. By using the Platform, you expressly
                    authorize the Company to access, collect, store, and use
                    such data for providing the Services. The Company is
                    required to collect User Data in accordance with the
                    Company's Privacy Policy, which is available for viewing in
                    Privacy Policy Section. You agree that once you initiate a
                    transaction using the relevant method as listed above, the
                    Company shall not be liable to recover the amounts due under
                    such transaction. You agree that the records maintained by
                    the Company regarding the transactions effected using the
                    Services shall be sufficient proof of such transactions. The
                    Company shall not be liable or responsible for any actual or
                    threatened losses, damages, expenses, disputes, or otherwise
                    any claims made.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <GetInTouch
          heading="Ready to transform your financial journeys?"
          subheading="We’ll make your business safer. Lets discuss how"
          buttonText="Explore APIs"
        />
      </div>
    </>
  );
};
