import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slider.css";
import sliderImage from "../assets/images/sliderImage.svg";

export const MultipleItems = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" />
          </div>
          <div className="sliderHeading">
            <h6>Data Collection & Global Analysis</h6>
          </div>
          <div className="sliderDescription">
            <p>
              Fstac’s IP Intelligence gathers extensive IP data from global
              sources, including geolocation, ISP details, and connection
              methods. Using sophisticated algorithms, this data is analyzed in
              real-time to detect threats and identify patterns, ensuring that
              your business is always ahead of potential risks.
            </p>
          </div>
        </div>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" />
          </div>
          <div className="sliderHeading">
            <h6>Data Collection & Global Analysis</h6>
          </div>
          <div className="sliderDescription">
            <p>
              Fstac’s IP Intelligence gathers extensive IP data from global
              sources, including geolocation, ISP details, and connection
              methods. Using sophisticated algorithms, this data is analyzed in
              real-time to detect threats and identify patterns, ensuring that
              your business is always ahead of potential risks.
            </p>
          </div>
        </div>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" />
          </div>
          <div className="sliderHeading">
            <h6>Real-Time Threat Detection & Response</h6>
          </div>
          <div className="sliderDescription">
            <p>
              As users interact with your platform, Fstac continuously monitors
              their behavior, capturing patterns such as navigation flows,
              clickstreams, and interaction timings. This real-time analysis
              allows the system to differentiate between legitimate user actions
              and potentially malicious activities, such as bots or automated
              scripts.
            </p>
          </div>
        </div>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" />
          </div>
          <div className="sliderHeading">
            <h6>Behavioral Pattern Recognition</h6>
          </div>
          <div className="sliderDescription">
            <p>
              The system goes beyond basic IP data, analyzing user behavior
              associated with each IP address. By identifying deviations from
              typical patterns, such as unexpected login locations or rapid IP
              switching, Fstac proactively mitigates potential fraud before it
              occurs.
            </p>
          </div>
        </div>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" />
          </div>
          <div className="sliderHeading">
            <h6>Data Collection & Global Analysis</h6>
          </div>
          <div className="sliderDescription">
            <p>
              Fstac’s IP Intelligence gathers extensive IP data from global
              sources, including geolocation, ISP details, and connection
              methods. Using sophisticated algorithms, this data is analyzed in
              real-time to detect threats and identify patterns, ensuring that
              your business is always ahead of potential risks.
            </p>
          </div>
        </div>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" className="img-fluid" />
          </div>
          <div className="sliderHeading ">
            <h6>Data Collection & Global Analysis</h6>
          </div>
          <div className="sliderDescription ">
            <p>
              Fstac’s IP Intelligence gathers extensive IP data from global
              sources, including geolocation, ISP details, and connection
              methods. Using sophisticated algorithms, this data is analyzed in
              real-time to detect threats and identify patterns, ensuring that
              your business is always ahead of potential risks.
            </p>
          </div>
        </div>
        <div className="sliderCard">
          <div className="sliderImage">
            <img src={sliderImage} alt="" />
          </div>
          <div className="sliderHeading">
            <h6>Data Collection & Global Analysis</h6>
          </div>
          <div className="sliderDescription">
            <p>
              Fstac’s IP Intelligence gathers extensive IP data from global
              sources, including geolocation, ISP details, and connection
              methods. Using sophisticated algorithms, this data is analyzed in
              real-time to detect threats and identify patterns, ensuring that
              your business is always ahead of potential risks.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}
