import "../styles/footer.css";
import logo from "../assets/images/logo.svg";
import linkedin_test from "../assets/images/in.svg";
import linkedinwhite from "../assets/images/linkedinwhite.svg";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div id="footer">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-2">
              <div
                className="footerLogo"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="listOfFooterItem">
                <ul>
                  <li
                    onClick={() => {
                      navigate("/deviceIntelligence");
                    }}
                  >
                    Products
                  </li>
                  {/* <li>Careers</li> */}
                  <li
                    onClick={() => {
                      navigate("/about");
                    }}
                  >
                    Company
                  </li>
                  <li
                    onClick={() => {
                      navigate("/privacyPolicy");
                    }}
                  >
                    Privacy Policy
                  </li>
                  <li
                    onClick={() => {
                      navigate("/termAndCondition");
                    }}
                  >
                    Terms & Conditions
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="socialMedia">
                <a
                  href="https://www.linkedin.com/company/fstac/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={linkedin_test} // Default image
                    alt="LinkedIn"
                    className="linkedin-image"
                  />
                  <img
                    src={linkedinwhite} // Hover image
                    alt="LinkedIn Hover"
                    className="linkedin-image-hover"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
