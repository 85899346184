// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-black: #212121;
    --primary-light-black: #495377;
    --primary-light-blue: #399cff;
    --primary-dark-blue: #175fc9;
    --primary-dark-blue-10: rgba(23, 95, 201, 0.1);
    --secondary-orange: #fa9f42;
    --secondary-green: #ccf5ac;
    --secondary-pastel-green: #dbf9f4;
    --secondary-pastel-blue: #b8e1ff;
    --secondary-pastel-yellow: #eeffdb;
    --secondary-pastel-purple: #e3dfff;
  }`, "",{"version":3,"sources":["webpack://./src/styles/variable.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,6BAA6B;IAC7B,4BAA4B;IAC5B,8CAA8C;IAC9C,2BAA2B;IAC3B,0BAA0B;IAC1B,iCAAiC;IACjC,gCAAgC;IAChC,kCAAkC;IAClC,kCAAkC;EACpC","sourcesContent":[":root {\n    --primary-black: #212121;\n    --primary-light-black: #495377;\n    --primary-light-blue: #399cff;\n    --primary-dark-blue: #175fc9;\n    --primary-dark-blue-10: rgba(23, 95, 201, 0.1);\n    --secondary-orange: #fa9f42;\n    --secondary-green: #ccf5ac;\n    --secondary-pastel-green: #dbf9f4;\n    --secondary-pastel-blue: #b8e1ff;\n    --secondary-pastel-yellow: #eeffdb;\n    --secondary-pastel-purple: #e3dfff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
