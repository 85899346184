import "./App.css";
import "./styles/variable.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/navbar";
import Footer from "./components/footer";
import { Home } from "./pages/home";
import { NewPage } from "./pages/description";
import { IpIntelligence } from "./pages/ipIntelligence";
import { BrowserIntelligence } from "./pages/browserIntelligence";
import { FraudRiskManagement } from "./pages/FraudRiskManagement";
import { DeviceIntelligence } from "./pages/deviceIntelligence";
import { DeviceAndBrowserIntelligence } from "./pages/deviceAndBrowserIntelligence";
import { About } from "./pages/about";
import { ComingSoonPage } from "./pages/comingSoon";
import { ContactUs } from "./pages/contactUs";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { TermAndCondition } from "./pages/termAndCondition";
import { ScrollToTop } from "./pages/scrollTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ipIntelligence" element={<IpIntelligence />} />
          <Route
            path="/browserIntelligence"
            element={<BrowserIntelligence />}
          />
          <Route
            path="/fraudRiskManagement"
            element={<FraudRiskManagement />}
          />
          <Route
            path="/deviceIntelligence"
            element={<DeviceAndBrowserIntelligence />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/document" element={<ComingSoonPage />} />
          <Route path="/blog" element={<ComingSoonPage />} />
          <Route path="/events" element={<ComingSoonPage />} />
          <Route path="/whitePapers" element={<ComingSoonPage />} />
          <Route path="/partner" element={<ComingSoonPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termAndCondition" element={<TermAndCondition />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
