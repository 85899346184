// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacyPolicyContent {
  background: rgba(236, 246, 255, 1);
  border-radius: 12px;
  padding: 5%;
  margin: 60px 0px;
}
#privacyPolicy p {
  text-align: center;
}
#privacyPolicy h6 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;

  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/privacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;;EAElB,kBAAkB;AACpB","sourcesContent":[".privacyPolicyContent {\n  background: rgba(236, 246, 255, 1);\n  border-radius: 12px;\n  padding: 5%;\n  margin: 60px 0px;\n}\n#privacyPolicy p {\n  text-align: center;\n}\n#privacyPolicy h6 {\n  font-family: \"Inter\", sans-serif;\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 32px;\n  text-align: center;\n\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
