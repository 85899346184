import "../styles/comingsoon.css";
import { Navigate, useNavigate } from "react-router-dom";
import eggGloabl from "../assets/images/eggGlobal.png";
export const ComingSoonPage = () => {
  const navigate = useNavigate();
  return (
    <div className="comingSoonBox">
      <div className="comingSoonContent">
        <div>
          <h2>
            We’re creating <br /> something amazing
          </h2>
        </div>
        <div>
          <p>
            Stay tuned for something amazing. This feature is under development
            to bring you a revolutionary new experience.
          </p>
        </div>
        <div className="backButton">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            Back to home
          </button>
        </div>
      </div>
      <div className="coming-image-icon1">
        <img src={eggGloabl} alt="" />
      </div>
      <div className="coming-image-icon2">
        <img src={eggGloabl} alt="" />
      </div>
      <div className="coming-image-icon3">
        <img src={eggGloabl} alt="" />
      </div>
      <div className="coming-image-icon4">
        <img src={eggGloabl} alt="" />
      </div>
    </div>
  );
};
