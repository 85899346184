import "../styles/getintouch.css";
import { useNavigate } from "react-router-dom";
export const GetInTouch = ({ heading, subheading, buttonText }: any) => {
  const nagivate = useNavigate();
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mb-5">
        <div className="col-lg-12">
          <div className="content4">
            <div className="heading1 text-center">
              <h2>{heading}</h2>
              <p>{subheading}</p>
            </div>

            <div className="text-center">
              <button
                className="contactus btn"
                onClick={() => {
                  nagivate("/contact");
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
