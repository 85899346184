import browdstric from "../assets/images/CrowdStrike_logo.png";
import aws from "../assets/images/Amazon_Web.png";
import elastic from "../assets/images/elastic.png";
import sysdin from "../assets/images/sysdig-inc-logo-vector 1.png";
import dataLogo from "../assets/images/dataLogo.png";
import prevent from "../assets/images/prevent.svg";
import fastergtm from "../assets/images/fasterGTM.svg";
import gobalIntelligence from "../assets/images/globalIntelligence.svg";
import stopGamingFraud from "../assets/images/stopgamingFraud.svg";
import multiAccount from "../assets/images/multiAccount.svg";
import minimizeAccount from "../assets/images/minimizeAccount.svg";

import "../styles/description.css";

export const WhyFtac = () => {
  return (
    <div className="container whyFstac pt-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-10" style={{ paddingTop: "58px" }}>
          <div className="sub_heading_why_Fstac text-center">Why Fstac?</div>
          <div className="main_heading_why_Fstac ">
            <h2 className="text-center">Secure, Insightful, Powerful</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-11">
          <div
            className="whyBox d-flex justify-content-between align-items-center"
            style={{ paddingTop: "132px" }}
          >
            <div className="whyChild">
              <div className="whyImage">
                <img src={prevent} alt="" />
              </div>
              <div className="whychildHeading pt-3">
                <h6>Prevent Account Takeovers</h6>
              </div>
              <div>
                <p style={{ paddingBottom: "0px" }}>
                  Safeguards customer accounts from unauthorized access.{" "}
                </p>
                <p>
                  Device and IP intelligence monitor suspicious login attempts,
                  and assign risk scores based on anomalies, blocking
                  unauthorised access.
                </p>
              </div>
            </div>
            <div className="whyChild">
              <div className="whyImage">
                <img src={multiAccount} alt="" />
              </div>
              <div className="whychildHeading pt-3">
                <h6>Stop Multi-Accounting</h6>
              </div>
              <div>
                <p style={{ paddingBottom: "0px" }}>
                  Prevents abuse of promotional offers and rewards programs{" "}
                </p>
                <p>
                  Device intelligence detects users creating multiple accounts,
                  and IP intelligence blocks attempts to manipulate IP addresses
                </p>
              </div>
            </div>
          </div>
          <div className="whyBox d-flex justify-content-between align-items-center pt-5">
            <div className="whyChild">
              <div className="whyImage">
                <img src={minimizeAccount} alt="" />
              </div>
              <div className="whychildHeading pt-3">
                <h6 style={{ lineHeight: "1.4" }}>
                  Minimise E-Commerce <br /> Chargebacks
                </h6>
              </div>
              <div>
                <p style={{ paddingBottom: "0px" }}>
                  Minimises chargebacks and payment fraud, securing
                  transactions.
                </p>
                <p>
                  Fstac's device Intelligence detects returning
                  fraudsters, monitors location mismatches and device spoofing.
                </p>
              </div>
            </div>
            <div className="whyChild">
              <div className="whyImage">
                <img src={stopGamingFraud} alt="" />
              </div>
              <div className="whychildHeading pt-3">
                <h6 style={{ lineHeight: "1.4" }}>
                  Stop Gaming Frauds and Chargebacks
                </h6>
              </div>
              <div>
                <p style={{ paddingBottom: "0px" }}>
                  Protects in-game assets and player accounts.{" "}
                </p>
                <p>
                  Device and browser intelligence identify cheaters using bots
                  or multiple accounts, and IP signals track suspicious
                  activity.
                </p>
              </div>
            </div>
          </div>
          <div className="whyBox d-flex justify-content-between align-items-center pt-5">
            <div className="whyChild">
              <div className="whyImage">
                <img src={gobalIntelligence} alt="" />
              </div>
              <div className="whychildHeading pt-3">
                <h6>Global Intelligence Network</h6>
              </div>
              <div>
                <p>
                  Leverage a vast network of global data to identify threats and
                  protect users across borders.
                </p>
              </div>
            </div>
            <div className="whyChild">
              <div className="whyImage">
                <img src={fastergtm} alt="" />
              </div>
              <div className="whychildHeading pt-3">
                <h6>Faster GTM</h6>
              </div>
              <div>
                <p>
                  Accelerate your go-to-market strategy with real-time fraud
                  prevention and seamless integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
