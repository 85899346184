import "../styles/home.css";
import secureLocker from "../assets/images/ShieldImage1.svg";
import Secure from "../pages/secure";
import { useNavigate } from "react-router-dom";

const TopHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="container topHeader">
      <div className="row align-items-center justify-content-around">
        <div className="col-lg-7 col-sm-12">
          <div className="frameGroup1">
            <div className="secureDetectPreventYourParent">
              <div className="secureDetectPreventContainer">
                <h2>{`Secure, Detect, Prevent. `}</h2>
                <h2>Your Business, Reinforced.</h2>
              </div>
              <div className="armYourBusiness">
                Arm Your Business with Fortified Fraud Detection and Resilient
                Protection
              </div>
              <div className="topHeaderButton">
                <button
                  type="button"
                  className="btn btn-primary discovery"
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Request Demo
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 topHeaderImage">
          <img
            className="vuesaxoutlinesecurityIcon"
            alt=""
            src={secureLocker}
          />
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
