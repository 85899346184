// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headingHowItStarted {
  font-family: "Gilroy-Bold";
  font-size: 54px;
  font-weight: 400px;
  color: rgba(50, 59, 75, 1);
}
.howItBox {
  background: rgba(236, 246, 255, 1);
  padding: 12%;
  border-radius: 30px;
  margin-top: 10px;
  position: relative;
}
.howItStarted {
  width: 73%;
}
.sourabh {
  position: absolute;
  top: 17%;
  right: 12%;
}
.Utkarsh {
  position: absolute;
  bottom: 17%;
  left: 10%;
}
.name {
  background: white;
  text-align: center;
  border-radius: 12px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.utkarshName {
  position: absolute;
  top: 50px;
  left: -54px;
}
.sourabhName {
  position: absolute;
  top: 50px;
  right: -54px;
}
.infoCeo {
  font-size: 20px;
  color: rgba(23, 95, 201, 1);
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.Info {
  padding: 40px;
}
.companyPeople {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #eae9e9;
}
`, "",{"version":3,"sources":["webpack://./src/styles/about.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,kCAAkC;EAClC,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;AACA;EACE,eAAe;EACf,2BAA2B;EAC3B,gBAAgB;EAChB,gCAAgC;AAClC;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".headingHowItStarted {\n  font-family: \"Gilroy-Bold\";\n  font-size: 54px;\n  font-weight: 400px;\n  color: rgba(50, 59, 75, 1);\n}\n.howItBox {\n  background: rgba(236, 246, 255, 1);\n  padding: 12%;\n  border-radius: 30px;\n  margin-top: 10px;\n  position: relative;\n}\n.howItStarted {\n  width: 73%;\n}\n.sourabh {\n  position: absolute;\n  top: 17%;\n  right: 12%;\n}\n.Utkarsh {\n  position: absolute;\n  bottom: 17%;\n  left: 10%;\n}\n.name {\n  background: white;\n  text-align: center;\n  border-radius: 12px;\n  padding: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n.utkarshName {\n  position: absolute;\n  top: 50px;\n  left: -54px;\n}\n.sourabhName {\n  position: absolute;\n  top: 50px;\n  right: -54px;\n}\n.infoCeo {\n  font-size: 20px;\n  color: rgba(23, 95, 201, 1);\n  font-weight: 600;\n  font-family: \"Inter\", sans-serif;\n}\n.Info {\n  padding: 40px;\n}\n.companyPeople {\n  height: 110px;\n  width: 110px;\n  border-radius: 50%;\n  object-fit: cover;\n  border: 1px solid #eae9e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
